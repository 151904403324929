import { Timeline } from "antd";
import React, { useMemo, useRef } from "react";
import {
  SmileOutlined,
  ClockCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { getTimeInHHMMSS } from "../../../workLog/utils";

function getStatusICon(status) {
  switch (status) {
    case "stop":
      return <SmileOutlined />;
    case "pause":
      return <WarningOutlined />;
    case "start":
      return <ClockCircleOutlined style={{ fontSize: "16px" }} />;

    default:
      break;
  }
}

export default function TimeLogDetails(props) {
  const { record, timeTracking, assignee, statusDict } = props;
  const emailNameMap = useRef(new Map());

  let timeLog = useMemo(
    () => timeTracking.filter((i) => i.wid === record.key),
    [record, timeTracking]
  );

  assignee.forEach((element) => {
    emailNameMap.current.set(element.email, element.name);
  });

  const timeLineItems = useMemo(
    () =>
      timeLog.map((item) => {
        return {
          label: new Date(item.time * 1000).toLocaleString("IN", {
            hour12: true,
          }),
          children: (
            <>
              <b>{emailNameMap.current.get(item.user)}</b> updated status to{" "}
              <b>{statusDict[item.st].name}</b> and timer set to{" "}
              <b>{item.ac}</b> state
            </>
          ),
          color: item.ac === "pause" ? "red" : "blue",
          dot: getStatusICon(item.ac),
        };
      }),
    [statusDict, timeLog]
  );
  let startTime = timeLog[0].time;
  let endTime = timeLog[timeLog.length - 1].time;
  let totalTime = endTime - startTime;

  return (
    <>
      <p className="center">
        <b>
          <u>
            Detailed timeline of work <i>{record.index}</i>
          </u>
        </b>
      </p>
      <br />
      <Timeline items={timeLineItems} mode="left" />
      <div className="time-info">
        <p className="head">
          <b>Summary</b>
        </p>
        <p>
          Total time elapsed on this work - <b>{getTimeInHHMMSS(totalTime)}</b>
        </p>
        <p>
          Total worked time - <b>{record.timeElapsed}</b>
        </p>
        Total blocked/paused time -{" "}
        <b>{getTimeInHHMMSS(totalTime - record.timeElapsedInSeconds)}</b>
      </div>
    </>
  );
}
