import { Menu } from "antd";
import { Link, Outlet } from "react-router-dom";
import "../settings/index.scss";

const menuOptions = [
  {
    key: "employees",
    label: (
      <Link to="employees">
        <span>Employees</span>
      </Link>
    ),
  },
  {
    key: "work-type",
    label: (
      <Link to="work-type">
        <span>Work Type</span>
      </Link>
    ),
  },
];

function Reports() {
  return (
    <div className="pms-settings">
      <Menu
        theme="light"
        selectedKeys={[window.location.href.split("/")[4]]}
        items={menuOptions}
      />
      <div className="view">
        <Outlet />
      </div>
    </div>
  );
}

export default Reports;
