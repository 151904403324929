import { Button, Col, Row, Table } from "antd";
import React, { useMemo, useState } from "react";
import { getTimeInHHMMSS } from "../../../workLog/utils";
import TimeLogDetails from "./timeLogDetails";

const columns = [
  {
    title: "Sl. No.",
    dataIndex: "index",
    key: "index",
  },
  {
    title: "Client",
    dataIndex: "client",
    key: "client",
  },
  {
    title: "Work Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Assignee Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Time Logged",
    dataIndex: "timeElapsed",
    key: "timeElapsed",
  },
];

export default function TimeLogTable(props) {
  const {
    employeeWork = [],
    timeWorkData,
    workTypesDict,
    statusDict,
    clientDict,
  } = props;

  const [selectedRecord, setSelectedRecord] = useState();

  function viewDetails(key) {
    setSelectedRecord(key);
  }

  let cols = useMemo(
    () => [
      ...columns,
      {
        title: "View Details",
        dataIndex: "actions",
        key: "action",
        width: "5%",
        render: (_, record) => (
          <Button type="link" onClick={() => viewDetails(record)}>
            details&gt;&gt;
          </Button>
        ),
      },
    ],
    []
  );

  let total = 0;

  let data = employeeWork.map((w) => {
    total += timeWorkData.get(w.key);
    return {
      index: w.index,
      client: clientDict[w.client]?.name,
      type: workTypesDict[w.type]?.name,
      status: statusDict[w.status]?.name,
      timeElapsed: getTimeInHHMMSS(timeWorkData.get(w.key)),
      timeElapsedInSeconds: timeWorkData.get(w.key),
      key: w.key,
    };
  });

  return (
    <Row>
      <Col span={12} className="timelog-table">
        <Table
          dataSource={data}
          columns={cols}
          pagination={false}
          rowClassName={(record, index) =>
            selectedRecord?.key === record.key ? "selected-row" : ""
          }
        />
        <h3 style={{ textAlign: "right", padding: "0 9.5rem 0 0" }}>
          Total logged time - <b>{getTimeInHHMMSS(total)}</b>
        </h3>
      </Col>
      <Col span={12} className="timeline-view">
        {selectedRecord ? (
          <TimeLogDetails record={selectedRecord} {...props} />
        ) : (
          <p style={{ textAlign: "center" }}>
            {"  "}Please select a record to view details
          </p>
        )}
      </Col>
    </Row>
  );
}
