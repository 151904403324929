export function getPermissions(email, userdData) {
  if (!email || !userdData) return false;
  let user = userdData.find((user) => user.email === email);

  let { permissions = {} } = user || {};
  return permissions;
}

export function getUser(email, userdData) {
  if (!email || !userdData) return false;
  let user = userdData.find((user) => user.email === email);
  return user;
}
