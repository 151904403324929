import React, { lazy } from "react";
import { Route } from "react-router-dom";
import Reports from ".";
import Employee from "./employee/employee";
const WorkTypeReport = lazy(() => import("./workType/workType"));

export const ReportRoutes = (
  // <Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
  <Route exact path="reports" element={<Reports />}>
    <Route exact path="employees" element={<Employee />} />
    <Route exact path="work-type" element={<WorkTypeReport />} />
  </Route>
  // </Suspense>
);
