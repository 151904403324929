import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo/logo.webp";
import "./login.scss";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const onFinish = async (values) => {
    const { email, password } = values;
    setLoading(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/work-log");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      message.error(
        errorCode === "auth/wrong-password" ? "Wrong password" : errorMessage
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <img src={logo} alt="logo" className="logo" />
        <p className="description">
          Practice Management Software for Professional CA firms
        </p>
        <div className="login-head">
          <h2>Log in to your account</h2>
        </div>
        <Form
          name="login"
          layout="vertical"
          wrapperCol={{
            span: 25,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { type: "email" },
              {
                required: true,
                message: "Please enter your email",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Enter your email"
              autoFocus
              size="large"
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Enter your password"
              size="large"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              size={"large"}
              style={{ width: "100%", borderRadius: "4px" }}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
