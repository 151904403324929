import { Select } from "antd";
import React, { useRef, useState } from "react";
import TimeLogReport from "./timeLogReport";
import MyDatePicker from "../../../components/Common/Datepicker";
import { dateFormat } from "../../../constants/DB";
import moment from "moment";

const { RangePicker } = MyDatePicker;

export default function EmpReport(props) {
  const { assignee = [], timeTracking } = props;
  const employeeOptions = assignee.map((e) => ({
    label: e.name,
    value: e.key,
  }));

  let empEmailMap = useRef(new Map());
  assignee.forEach((emp) => empEmailMap.current.set(emp.key, emp.email));

  const [selectedEmployee, setSelectedEmployee] = useState();
  const [date, setDate] = useState([moment(), moment()]);

  return (
    <div>
      <Select
        showSearch
        placeholder="Select employee"
        optionFilterProp="children"
        onChange={setSelectedEmployee}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={employeeOptions}
        style={{ marginRight: "1rem", minWidth: "200px" }}
      />

      <RangePicker
        format={dateFormat}
        onChange={(d) => setDate(d)}
        value={date}
      />
      <br />
      <br />
      <TimeLogReport
        empEmailMap={empEmailMap.current}
        {...{
          timeTracking,
          selectedEmployee,
          date,
        }}
        {...props}
      />
    </div>
  );
}
