// import { DatePicker } from "antd";
// import momentGenerateConfig from "rc-picker/es/generate/moment";

// const MyDatePicker = DatePicker.generatePicker(momentGenerateConfig);

// export default MyDatePicker;

import generatePicker from "antd/es/date-picker/generatePicker";
import momentGenerateConfig from "rc-picker/lib/generate/moment";

const MyDatePicker = generatePicker(momentGenerateConfig);

export default MyDatePicker;
