import React, { lazy, Suspense, useEffect, createContext } from "react";
import { Alert, Skeleton } from "antd";
import AppMenu from "./components/AppMenu";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./components/Authentication/Login";

import { useAuth } from "./customHooks/useAuth";
import { useQuery } from "@tanstack/react-query";
import { getDataByDB } from "./services/getDataByDB";
import { DB } from "./constants/DB";
import { getUser, getPermissions } from "./utils/permission";
import "./App.scss";
import { useIsOnline } from "./customHooks/useIsOnline";
import { ReportRoutes } from "./pages/reports/routes";

const Clients = lazy(() => import("./pages/clients"));
const WorkLog = lazy(() => import("./pages/workLog"));
const Settings = lazy(() => import("./pages/settings"));

const Credentials = lazy(() => import("./pages/settings/credentials"));
const AssigneeStatus = lazy(() => import("./pages/settings/assigneeStatuses"));
const AdminStatus = lazy(() => import("./pages/settings/adminStatus"));
const Constitution = lazy(() => import("./pages/settings/constitution"));
const Employees = lazy(() => import("./pages/settings/employees"));
const WorkType = lazy(() => import("./pages/settings/workType"));
const Organizations = lazy(() => import("./pages/settings/organizations"));
const InvoiceList = lazy(() => import("./pages/invoices/list"));

export const AuthContext = createContext(null);

function App() {
  let navigate = useNavigate();
  const { pending, isSignedIn, user } = useAuth();

  const assignee = useQuery([DB?.employee], () => getDataByDB(DB?.employee));
  let permissions = getPermissions(user?.email, assignee.data);
  let settingsAllowed = permissions?.settings || false;
  let workLogAllowed = permissions?.workLog || false;
  let clientList = permissions?.clientList || false;
  let invoiceList = permissions?.invoiceList || false;

  let employee = getUser(user?.email, assignee.data);
  const isOnline = useIsOnline();

  useEffect(() => {
    if (isSignedIn) {
      navigate("/my-work");
    } else {
      navigate("/login");
    }
  }, [isSignedIn]);

  if (pending) {
    return <Skeleton paragraph={{ rows: 4 }} />;
  }

  return (
    <>
      {!isOnline ? (
        <Alert
          message="Unable to connect to the internet. Please check your network connection and try again."
          type="warning"
          showIcon
        />
      ) : null}

      <AuthContext.Provider value={employee}>
        {user && (
          <AppMenu
            settingsAllowed={settingsAllowed}
            permissions={permissions}
          />
        )}
        <Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
          <main>
            <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/" element={<Login />} />
              {user && (
                <>
                  {clientList && (
                    <Route
                      exact
                      path="clients"
                      element={<Clients permissions={permissions} />}
                    />
                  )}
                  {workLogAllowed && (
                    <Route
                      exact
                      path="work-log"
                      element={<WorkLog permissions={permissions} />}
                    />
                  )}
                  <Route
                    exact
                    path="my-work"
                    element={
                      <WorkLog permissions={permissions} myWork={true} />
                    }
                  />
                  {invoiceList && (
                    <Route
                      exact
                      path="invoices"
                      element={<InvoiceList permissions={permissions} />}
                    />
                  )}
                  {workLogAllowed && ReportRoutes}
                  {settingsAllowed && (
                    <Route exact path="settings" element={<Settings />}>
                      <Route exact path="work-types" element={<WorkType />} />
                      <Route exact path="employees" element={<Employees />} />
                      <Route
                        exact
                        path="assigneeStatus"
                        element={<AssigneeStatus />}
                      />
                      <Route
                        exact
                        path="adminStatus"
                        element={<AdminStatus />}
                      />
                      <Route
                        exact
                        path="credentials"
                        element={<Credentials />}
                      />
                      <Route
                        exact
                        path="constitution"
                        element={<Constitution />}
                      />
                      <Route
                        exact
                        path="organizations"
                        element={<Organizations />}
                      />
                    </Route>
                  )}
                </>
              )}
            </Routes>
          </main>
        </Suspense>
      </AuthContext.Provider>
    </>
  );
}

export default App;
