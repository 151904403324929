import React from "react";
import { calculateTimePerWidInRange } from "./utils";
import TimeLogTable from "./logTable/timeLogTable";

export default function TimeLogReport(props) {
  const { empEmailMap, timeTracking, selectedEmployee, date, work } = props;

  let empId = empEmailMap.get(selectedEmployee);
  let employeTimeLog = timeTracking.filter((t) => t.user === empId);

  if (!date || !selectedEmployee)
    return (
      <>Please select an employee and date range to view time log report</>
    );

  let start = date[0].startOf("day").unix();
  let end = date[1].endOf("day").unix();
  let result = calculateTimePerWidInRange(employeTimeLog, start, end);
  if (result.size === 0)
    return <>No time log found for the employee on selected dates.</>;

  let worksIds = Array.from(result.keys());
  let employeeWork = work.filter((w) => worksIds.includes(w.key));

  return (
    <TimeLogTable
      employeeWork={employeeWork}
      timeWorkData={result}
      {...props}
    />
  );
}
