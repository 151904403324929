import React from "react";
import { Menu, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../../customHooks/useAuth";
import { COMPANY_NAME } from "../../firebase/init";

const getMenuOptions = (
  currentUser,
  settingsAllowed,
  workLogAllowed,
  invoiceList,
  clientList
) => {
  let options = [];
  options.push({
    key: "my-work",
    label: (
      <Link to="/my-work">
        <span className="menu-item">My Work</span>
      </Link>
    ),
  });
  if (workLogAllowed) {
    options.push({
      key: "work-log",
      label: (
        <Link to="/work-log">
          <span className="menu-item">Work Log</span>
        </Link>
      ),
    });
  }

  if (clientList) {
    options.push({
      key: "clients",
      label: (
        <Link to="/clients">
          <span className="menu-item">Clients</span>
        </Link>
      ),
    });
  }

  if (invoiceList) {
    options.push({
      key: "invoices",
      label: (
        <Link to="/invoices">
          <span className="menu-item">Invoices</span>
        </Link>
      ),
    });
  }

  if (workLogAllowed) {
    options.push({
      key: "reports",
      label: (
        <Link to="/reports/employees">
          <span className="menu-item">Reports</span>
        </Link>
      ),
    });
  }

  options.push({
    label: <h1 className="company-name">{COMPANY_NAME}</h1>,
    key: "company-name",
    style: { margin: "auto" },
  });

  if (settingsAllowed) {
    options.push({
      key: "settings",
      label: (
        <Link to="/settings/employees">
          <span className="menu-item">Settings</span>
        </Link>
      ),
      style: { marginLeft: "auto" },
    });
  }

  options.push({
    label: currentUser || "User",
    key: "use-actions",
    children: [{ label: "Logout", key: "logout" }],
    style: { marginLeft: settingsAllowed ? "" : "auto" },
  });

  return options;
};

const AppMenu = (props) => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();
  const auth = getAuth();
  const { user } = useAuth();

  const { settingsAllowed, permissions } = props;
  let workLogAllowed = permissions?.workLog || false;
  let invoiceList = permissions?.invoiceList || false;
  let clientList = permissions?.clientList || false;

  function logout() {
    signOut(auth)
      .then(() => {
        queryClient.clear();
        navigate("/login");
      })
      .catch((error) => {
        message.error("Logout failed, please try again");
      });
  }

  function onClick(params) {
    if (params.key === "logout") logout();
  }

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[window.location.href.split("/")[3]]}
      items={getMenuOptions(
        user?.email,
        settingsAllowed,
        workLogAllowed,
        invoiceList,
        clientList
      )}
      onClick={onClick}
    />
  );
};

export default AppMenu;
