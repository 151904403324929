import * as XLSX from "xlsx";
import { dateFormat } from "../../constants/DB";
import moment from "moment";

export function getFilterMap(
  data,
  clientsMap,
  workTypeMap,
  assigneeMap,
  statusMap,
  adminStatusMap
) {
  let filterMap = {
    client: {},
    type: {},
    assignee: {},
    status: {},
    adminStatus: {},
  };

  data.forEach((element) => {
    filterMap.client[element.client] = clientsMap.get(element.client);
    filterMap.type[element.type] = workTypeMap.get(element.type);
    filterMap.assignee[element.assignee] = assigneeMap.get(element.assignee);
    filterMap.status[element.status] = statusMap.get(element.status);
    filterMap.adminStatus[element.adminStatus] = adminStatusMap.get(
      element.adminStatus
    );
  });

  let keys = Object.keys(filterMap);

  keys.forEach((key) => {
    let keysColumn = Object.keys(filterMap[key]);
    let res = [];
    keysColumn.forEach((k) => {
      res.push({ text: filterMap[key][k], value: k });
    });
    res.sort((a, b) => a?.text?.localeCompare(b?.text));
    filterMap[key] = res;
  });

  return filterMap;
}

export function getFormattedDates(dateFilter) {
  let start = dateFilter[0]?.toDate();
  let end = dateFilter[1]?.toDate();

  start.setHours(0);
  start.setMinutes(0);
  start.setSeconds(0);

  end.setHours(23);
  end.setMinutes(59);
  end.setSeconds(59);
  return { start, end };
}

export function getTimeElapsed(data) {
  if (!data || data.length === 0) return "0";
  let startTime = 0;
  let elapsedTime = 0;
  let lastStatus = data[data.length - 1].ac;
  for (let i = 0; i < data.length; i++) {
    if (data[i].ac === "start") {
      startTime = data[i].time;
    } else if ((data[i].ac === "pause" || data[i].ac === "stop") && startTime) {
      elapsedTime += data[i].time - startTime;
      startTime = 0;
    }
  }
  if (lastStatus === "start") {
    elapsedTime += Date.now() / 1000 - startTime;
  }
  return elapsedTime;
}

export function downloadWorkLog(
  clientsMap,
  workTypeMap,
  assigneeMap,
  statusMap,
  adminStatusMap,
  data
) {
  let record = data.map((item) => {
    return {
      "Sl. No": item.index,
      Client: clientsMap.get(item.client),
      "Work Type": workTypeMap.get(item.type),
      "Date of assignment": item.date,
      "Person in charge": assigneeMap.get(item.assignee),
      "Assignee Status": statusMap.get(item.status),
      "Person verified 1": assigneeMap.get(item.l1_verifier),
      "Person verified 2": assigneeMap.get(item.l2_verifier),
      "Due date": item.dueDate,
      "Completed date": item.completedDate,
      Invoice: item.invoiceRaised ? "Yes" : "No",
      Payment: item.paymentReceived ? "Yes" : "No",
      "Admin Status": adminStatusMap.get(item.adminStatus),
    };
  });

  const worksheet = XLSX.utils.json_to_sheet(record);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workbook, `Worklog(${moment().format(dateFormat)}).xlsx`);
}

export function getTimeInHHMMSS(time) {
  let hours = Math.floor(time / 3600);
  let minutes = Math.floor((time % 3600) / 60);
  let seconds = Math.floor(time % 60);
  let result = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  return result;
}
