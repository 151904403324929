// total for all wids
export function calculateTimePerWidInRange(
  data,
  startDate,
  endDate,
  total = false
) {
  if (!data) return;
  if (!total) {
    if (!startDate || !endDate) return;
  }

  let filteredData = data;

  if (!total) {
    filteredData = data.filter((entry) => {
      const entryDate = entry.time;
      return entryDate >= startDate && entryDate <= endDate;
    });
  }

  const widMap = new Map();
  const widStartMap = new Map();

  filteredData.forEach((entry) => {
    const wid = entry.wid;

    if (entry.ac === "start") {
      widStartMap.set(wid, entry.time);
    } else if (
      (entry.ac === "stop" || entry.ac === "pause") &&
      widStartMap.get(wid)
    ) {
      let time = (widMap.get(wid) || 0) + entry.time - widStartMap.get(wid);
      widMap.set(wid, time);
      widStartMap.set(wid, 0);
    }
  });

  return widMap;
}
