import { useQuery } from "@tanstack/react-query";
import { Button, Result, Skeleton } from "antd";

import { getDataByDB, getDataByDBInDict } from "../../services/getDataByDB";
import { DB } from "../../constants/DB";

function Wrapper(props) {
  let { WrappedComponent } = props;
  const work = useQuery([DB?.workLog], () => getDataByDB(DB?.workLog));
  const workTypes = useQuery([DB?.workTypes], () => getDataByDB(DB?.workTypes));

  const workTypesDict = useQuery(["workTypesDB"], () =>
    getDataByDBInDict(DB?.workTypes)
  );

  const status = useQuery([DB?.assigneeStatus], () =>
    getDataByDB(DB?.assigneeStatus)
  );
  const statusDict = useQuery(["assigneeStatusDB"], () =>
    getDataByDBInDict(DB?.assigneeStatus)
  );
  const clientDict = useQuery(["clientsDB"], () =>
    getDataByDBInDict(DB?.clients)
  );

  const assignee = useQuery([DB?.employee], () => getDataByDB(DB?.employee));
  // const clients = useQuery([DB?.clients], () => getDataByDB(DB?.clients));

  const timeTracking = useQuery([DB?.timeLog], () =>
    getDataByDBInDict(DB?.timeLog)
  );

  if (
    work.isError ||
    workTypes.isError ||
    status.isError ||
    assignee.isError ||
    // clients.isError ||
    statusDict.isError ||
    timeTracking.isError ||
    clientDict.isError ||
    workTypesDict.isError
  ) {
    return (
      <Result
        status="warning"
        title="Something went wrong"
        extra={
          <Button type="primary" key="console" onClick={window.location.reload}>
            Refresh
          </Button>
        }
      />
    );
  }

  const loading =
    work.isLoading ||
    workTypes.isLoading ||
    // clients.isLoading ||
    status.isLoading ||
    statusDict.isLoading ||
    assignee.isLoading ||
    clientDict.isLoading ||
    timeTracking.isLoading ||
    workTypesDict.isLoading;

  if (loading) {
    return <Skeleton paragraph={{ rows: 4 }} />;
  }

  let dataProps = {
    work: work.data,
    workTypes: workTypes.data,
    // clients: clients.data,
    status: status.data,
    assignee: assignee.data,
    statusDict: statusDict.data,
    clientDict: clientDict.data,
    workTypesDict: workTypesDict.data,
    timeTracking: Object.values(timeTracking?.data || {}),
  };

  return <WrappedComponent {...props} {...dataProps} />;
}

export function withApi(component) {
  return function (props) {
    return <Wrapper {...props} WrappedComponent={component} />;
  };
}
